//export const msalConfig = {
//  auth: {
//    clientId: "bff3dc6a-285b-4a8e-83b6-0210cee22f38",
//    authority: "https://login.microsoftonline.com/organizations",
//    redirectUri: window.location.origin + "/home",
//    navigateToLoginRequestUrl: false,
//    origin: window.location.origin
//  },
//    cache: {
//    cacheLocation: "sessionStorage", // This configures where your cache will be stored
//    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
//  }
//};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid", "email"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
//export const graphConfig = {
//  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
//  graphPhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value"
//};