import React from "react"
import { Typography, Grid, Box, IconButton, } from '@mui/material';
import { useHistory } from "react-router-dom"
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Activities from './Activities';
import jwt_decode from "jwt-decode";
import { UserRoles } from "./AccuracyService/Model/UserRoles";
import BosonApiService from "./services/BosonApi.Service";
import { Claim } from "./AccuracyService/Model/Claim";



export const IVServices = () => {

  const [claim, setClaim] = React.useState<Claim>({
    info:{
    sysId:'',
    isActive:false,
    eTag:null,
    identifier:0,
    name:'',
    lastName:'',
    emailId:'',
    fullName:'',
    isAdministrator: false,
    iciEnvironments: '',
    isPartnerUser:false,
    },
    userAllowedRoles:'',
    userEnvironments:''
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const boxSX = {
    "&:hover": {
      color: '#6432A5',
    },
  };

  React.useEffect(()=>{
    try{
    BosonApiService.getUserClaims()
    .then((res)=>{
      setClaim(res.data);
    });
    }
    catch(e){
      console.log(e);
    }
  },[]);

  React.useEffect(()=>{
    console.log(claim);
  },[claim]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{textAlign:"right"}}>
          <div>
             <IconButton title="View User Manual" onClick={()=>{BosonApiService.donloadManual()}}>
              <img src="/images/pdfIcon.png"/>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} p={2} sx={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 8px', borderTop: '7px solid #6432A5', backgroundColor: '#F8F8F8', borderRadius: '10px' }}>
          <Typography variant="h5" component="h5">Tools</Typography>
          <Tabs
            TabIndicatorProps={{
              sx: {
                display: "none"
              }
            }}
            value={value} variant="scrollable" scrollButtons onChange={handleChange} aria-label="icon label tabs example" sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

              {(claim.info.isAdministrator==true?true:claim.userAllowedRoles.indexOf(UserRoles.IV)>-1) && (
              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/ivtool/batchList", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/accuracy.png" height={42} alt="Independent Validation (IV)" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Independent Validation (IV)" />
                  <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Calculate accuracy of Discover AI and subcomponents automatically, given a corpus and its corresponding annotations" /></Box></Grid>
                </Box></Box>
              </Grid>)}

              {((claim.info.isAdministrator==true?true:claim.userAllowedRoles.indexOf(UserRoles.TS)>-1)) && false && (
              <Grid item xs={6}>
                                  <Box onClick={() => { window.open('/classification/samplerbatches', '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/sampling.png" height={42} alt="Text Sampler (TS)" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Text Sampler (TS)" />
                  <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Find samples for annotations or templates from a given corpus" /></Box></Grid>
                </Box></Box>
              </Grid>)}
                
                          {((claim.info.isAdministrator==true ? true : claim.userAllowedRoles.indexOf(UserRoles.OCR) > -1)) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/ocr/ocrbatches", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/ocr-new.png" height={42} alt="Optical Character Recognition (OCR)" />
                                      <Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Optical Character Recognition (OCR)" />
                                          <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Optical Character Recognition" /></Box></Grid>
                                      </Box>
                                  </Box>
                              </Grid>)}

                          {((claim.info.isAdministrator==true  ? true : claim.userAllowedRoles.indexOf(UserRoles.LT) > -1)) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/LanguageTranslation/Batches", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/LT-1.png" height={42} alt="Language Translation" />
                                      <Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Language Translation" />
                                          <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Translate documents from one language into another" /></Box></Grid>
                                      </Box>
                                  </Box>
                              </Grid>)}

                {((claim.info.isAdministrator==true?true:claim.userAllowedRoles.indexOf(UserRoles.IGPTD)>-1)) && (false) && (
                  <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/OpenAITools/openAIbatchList", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/discover.png" height={42} alt="I-Intelli-AI" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Icertis-GPT-Discovery" />
                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Icertis-GPT-Discovery" /></Box></Grid>
                    </Box></Box>
                  </Grid>)}

                          {((claim.info.isAdministrator == true ? true : claim.userAllowedRoles.indexOf(UserRoles.IGPTP) > -1)) && (false) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/OpenAITools/OpenAIGround", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/discover.png" height={42} alt="Icertis-GPT" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Icertis-GPT-Playground" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Icertis-GPT-Playground" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}

                          {((claim.info.isAdministrator == true ? true : claim.userAllowedRoles.indexOf(UserRoles.IGPTCAI) > -1)) && (false) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/OpenAITools/OpenAIConversation", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/discover.png" height={42} alt="Icertis-GPT-Conversation-AI" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Icertis-GPT-Conversation-AI" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Icertis-GPT-Conversation-AI" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}

                          {((claim.info.isAdministrator==true ? true : claim.userAllowedRoles.indexOf(UserRoles.RULE_BUILDER) > -1)) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open('/RuleBuilderUI/Home', '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/discover.png" height={42} alt="ML Rule Builder" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="ML Rule Builder" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="ML Rule Builder" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}
                          {((claim.info.isAdministrator==true ? true : claim.userAllowedRoles.indexOf(UserRoles.SA) > -1)) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open('/StatisticalAnalysis/home', '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/SA-Image.png" height={42} alt="Statistical Text Analysis" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Statistical Text Analysis" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Statistical Text Analysis" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}
                          {((claim.info.isAdministrator == true ? true : claim.userAllowedRoles.indexOf(UserRoles.ADMIN) > -1)) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open('/admin/adminconfiguration', '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/admin.png" height={42} alt="Admin Portal" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="Admin Portal" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Admin Configuration" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}

                          {((claim.info.isAdministrator == true ? true : claim.userAllowedRoles.indexOf(UserRoles.PromptGenPG) > -1)) && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/promptgenerator/home", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/PromptGeneratorPlayground.png" height={42} alt="ExploreAI Prompt Generator" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="ExploreAI Prompt Generator" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Best initial prompt for ExploreAI configuration." /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}

                          {((claim.info.isAdministrator == true ? true : claim.userAllowedRoles.indexOf(UserRoles.BPG) > -1)) && false && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/playground/batchList", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/EAI-Corpus-Playground.png" height={42} alt="ExploreAI Corpus Playground" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="ExploreAI Corpus Playground" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Ability to execute prompts on batch of documents" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}

                          {((claim.info.isAdministrator == true ? true : claim.userAllowedRoles.indexOf(UserRoles.IPG) > -1)) && false && (
                              <Grid item xs={6}>
                                  <Box onClick={() => { window.open("/interactiveplayground/ContractDetails", '_blank').focus(); }} sx={{ display: 'flex', color: '#000000' }} p={2}><img src="images/EAI-Document-Playground.png" height={42} alt="ExploreAI Document Playground" /><Box sx={boxSX} mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit' }} label="ExploreAI Document Playground" />
                                      <Grid item xs={12}><Box mt={-2}><Tab sx={{ textAlign: 'left', textTransform: 'inherit', color: '#404041' }} label="Ability to Ask Questions on document level" /></Box></Grid>
                                  </Box></Box>
                              </Grid>)}
                

            </Grid>

          </Tabs>
        </Grid>

        {/*<Grid item xs={12} mt={2} mb={2} p={2} sx={{ borderRadius: '10px' }}>*/}
        {/*  <Typography variant="h5" component="h5">Activities</Typography>*/}
        {/*  <Activities />*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
}