import axios from "axios";
import jwtDecode from "jwt-decode";

// const BASE_URL = 'https://localhost:44389/api/';
// https://aimlportal.icertis.com/api
const BASE_URL = '/api/';


const ValidateAccessToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    const jsonToken = jwtDecode(accessToken);
    const expiryInUTC = jsonToken?.exp;
    const currentTimeInUTC = Math.round(new Date().getTime() / 1000)
    if (currentTimeInUTC >= expiryInUTC) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('token');
        window.location.href = window.location.origin + "/default";
    }
}

const HttpService = {
    get: function (urlParam) {
        ValidateAccessToken();
        const apiURL = `${BASE_URL}${urlParam}`;
        const token = localStorage.getItem('jwtToken');   
        const accessToken = localStorage.getItem('token')     
        const config = {
        headers: {
            "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
                "AccessToken": `${accessToken}`
            },
        };
        return axios.get(apiURL, config);
    },
    getControl: function (urlParam) {
        ValidateAccessToken();
        const apiURL = "/Contract/TextControl";
        return axios.get(apiURL)
    },
    post: function (urlParam, payload) {
        ValidateAccessToken();
        const apiURL = `${BASE_URL}${urlParam}`;
        const token = localStorage.getItem('jwtToken'); 
        const accessToken = localStorage.getItem('token')       
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "AccessToken": `${accessToken}`
            },
        };
        return axios.post(apiURL, payload, config);
    },
    postFile: function (urlParam, payload) {
        ValidateAccessToken();
        const apiURL = `${BASE_URL}${urlParam}`;
        const token = localStorage.getItem('jwtToken');  
        const accessToken = localStorage.getItem('accessToken')     
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "azureAccessToken": accessToken,
                "AccessToken": `${localStorage.getItem('token')}`
            },
        };
        return axios.post(apiURL, payload, config);
    },


    login: function (urlParam, userName, password, organisationName) {
        const apiURL = `${BASE_URL}${urlParam}`;
        const token = localStorage.getItem('token');  
        const loginConfig = {
            headers: {
                "Content-type": "application/json",
                "UserName": `${userName}`,
                "Email": `${password}`,
                "OrganizationName": `${organisationName}`,
                 "Authorization": `Bearer ${token}`,
                "AccessToken": `${token}`
            },
        };
        return axios.get(apiURL, loginConfig);
    },
    
    download: function (urlParam, fileName, payload) {
        ValidateAccessToken();
        const apiURL = `${BASE_URL}${urlParam}`;
        const token = localStorage.getItem('jwtToken');
        const accessToken = localStorage.getItem('token') 
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "AccessToken": `${accessToken}`
            },
        };
        fetch(apiURL,{
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
                "AccessToken": `${accessToken}`
            },
            body: JSON.stringify(payload)
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                //document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                //link.parentNode.removeChild(link);
            });
    },
    downloadResultsFiles: function (urlParam, fileName) {
        ValidateAccessToken();
        const apiURL = `${BASE_URL}${urlParam}`;
        const token = localStorage.getItem('jwtToken');
        const accessToken = localStorage.getItem('token') 
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "AccessToken": `${accessToken}`
            },
        };
        fetch(apiURL,{
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
                "AccessToken": `${accessToken}`
            },
            //body: JSON.stringify(payload)
        })
            .then((response) =>response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
        }
}
export default HttpService;