import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';

export default function Footer() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);
  
  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(_event, newValue) => {
            setValue(newValue);
          }}
        >
          <Box mt={1}><img src='images/icertislogo.svg' height='32' alt='Icertis Logo'/></Box>
        </BottomNavigation>
        <div style={{width:"100%",textAlign:"center",marginBottom:"10px"}}>© Copyright {new Date().getFullYear()} Icertis. All rights reserved.</div>
      </Paper>
    </Box>
  );
}
