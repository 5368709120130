import React from 'react'
import { Box, Typography, LinearProgress, LinearProgressProps, Button } from '@mui/material';
import { useHistory } from "react-router-dom"

export const Progress = () => {
    const [progress, setProgress] = React.useState(10);
    let history = useHistory();

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 800);
        return () => {
            console.log("Timer end")
            clearInterval(timer);
            history.push("/batch-uploaded");
        };
    }, [history]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={progress} />
            </Box>
        </>
    )
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    let history = useHistory();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
            <Button variant="outlined" onClick={() => { history.push("/batch-uploaded"); }}>Done</Button>

        </Box>
    );
}