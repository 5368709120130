import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, IconButton, Typography } from "@mui/material"
import React from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory, useLocation } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { AttributeFilter, AttributeListFilter } from "../AccuracyService/AccuracyInterfaces";
import BosonApiService from "../services/BosonApi.Service";
import Download from "@mui/icons-material/Download";
import { useIsAuthenticated } from "@azure/msal-react";
import { PlayCircleFilled } from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import { UserRoles } from "../AccuracyService/Model/UserRoles";
import { useCallback } from "react";

enum AttributeFilterEnum {
    OOTB = 'ootb',
    Discoverd = 'discovered',
    Annoted = 'annoted'
};

export const Result = () => {
    let location = useLocation();
    let history = useHistory();

    const inputBatchId = (new URLSearchParams(location.search)).get('batch') || location?.state?.batchId;
    const jobId = (new URLSearchParams(location.search)).get('job') || location?.state?.jobId;

    //console.log(inputBatchId);
    //console.log(jobId);


    const isAuthenticated = useIsAuthenticated();

    React.useEffect(() => {
        if (!isAuthenticated) {
            localStorage.setItem('location', window.location.href);
            history.push('/');
        }
    }, [isAuthenticated]);
    const [user, setUser] = React.useState<any>({
        ICIEnvironments: "",
        Identifier: "",
        IsAdmin: false,
        Roles: "",
        email: "",
        exp: 0,
        iat: 0,
        nbf: 0,
        unique_name: ""
    });

    React.useEffect(() => {
        try {
            let updatedUser = { ...user };
            updatedUser = jwt_decode(localStorage.getItem('jwtToken'));
            setUser(updatedUser);            
        }
        catch (e) {
            console.log(e);
        }
    }, []);

    //Check user authorization and redirect if necessary
    React.useEffect(() => {
        try {
            const user: any = jwt_decode(localStorage.getItem('jwtToken'));
            if (!(user?.IsAdmin == "True" ? true : user.Roles.indexOf(UserRoles.IV) > -1)) {
                history.push("/");
            }
        }
        catch (e) {
            console.log(e);
            history.push("/");
        }
    }, []);

    const [expandedAttribute, setExpandedAttribute] = React.useState<boolean>(true);
    const [expandedClauses, setExpandedClauses] = React.useState<boolean>(false);
    const [expandedObligation, setExpandedObligation] = React.useState<boolean>(false);

    const [ootbAttributeFilter, setOotbAttributeFilter] = React.useState<string>("True");
    const [discoveredAttributeFilter, setDiscoveredAttributeFilter] = React.useState<string>("True");
    const [annotedAttributeFilter, setAnnotedAttributeFilter] = React.useState<string>("True");
    const [additionalAnnotedAttributeFilter, setAdditionalAnnotedAttributeFilter] = React.useState<string>("True");
    const [attributeFilterOption, setAttributeFilterOption] = React.useState<AttributeFilter>({
        ootb: "True",
        discovered: "True",
        annotated: "True",
    })

   

    const handleChange =
        () => {
            setExpandedAttribute(!expandedAttribute);
        };


    const handleClausesSectionChange =
        () => {
            setExpandedClauses(!expandedClauses);
        };

    const handleObligationChange =
        () => {
            setExpandedObligation(!expandedObligation);
        };

    const handleClick =
        () => {
            setAttributeFilterOption({
                ootb: ootbAttributeFilter,
                discovered: discoveredAttributeFilter,
                annotated: annotedAttributeFilter,
            })
        };

    const handleFilterClick = (param: string, paramValue: string) => {
        switch (param.toLowerCase()) {
            case AttributeFilterEnum.OOTB:
                setOotbAttributeFilter(paramValue);
            case AttributeFilterEnum.Discoverd:
                setDiscoveredAttributeFilter(paramValue);
            case AttributeFilterEnum.Annoted:
                setAnnotedAttributeFilter(paramValue);
            default:
                ;
        }
    };

    return (
        <>
            <Box></Box>
        </>
    )
}