export enum UserRoles {
    IV = "Independent Validation (IV)",
    TS = "Text Sampler (TS)",
    IGPTD = "Icertis-GPT-Discovery",
    IGPTP = "Icertis-GPT-Playground",
    IGPTCAI = "Icertis-GPT-Conversation-AI",
    ATT = "Attribute",
    CLS = "Clause",
    OBL = "Obligation",
    RULE_BUILDER = 'ML Rule Builder',
    OCR = 'Optical Character Recognition (OCR)',
    LT = 'Language Translation (LT)',
    SA = 'Statistical Analysis',
    ADMIN = 'Admin Interface',
    BPG = "Bulk Playground",
    PromptGenPG = "PromptGeneration-Playground",
    IPG = "Interactive Playground"
};