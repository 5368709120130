import React from "react";
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import CommonDataManager from "../util/CommonDataManager";

// Breadcrumb data
export const routes = [
    { path: "/home", breadcrumb: [] },
    { path: "/batchList", breadcrumb: [{ name: "Independent Validation (IV)" }] },
    { path: "/CreateBatch", breadcrumb: [{ name: "Independent Validation (IV)", path: "/batchList" }, { name: "Create Batch" }] },
    { path: "/result", breadcrumb: [{ name: "Independent Validation (IV)", path: "/batchList" }, { name: "Result" }] },
    { path: "/batchprogress", breadcrumb: [{ name: "Independent Validation (IV)", path: "/batchList" }, { name: "Batch Progress" }] },

    { path: "/samplerbatches", breadcrumb: [{ name: "Text Sampler (TS)" }] },
    { path: "/createsamplerbatch", breadcrumb: [{ name: "Text Sampler (TS)", path: "/samplerbatches" }, { name: "Create Batch" }] },
    { path: "/editBatch", breadcrumb: [{ name: "Independent Validation (IV)", path: "/editBatch" }, { name: "Create Batch" }] },
    { path: "/attributemapping", breadcrumb: [{ name: "Edit Attribute Mapping", path: "/attributemapping" }, { name: "Edit Attribute Mapping" }] },
    { path: "/samplerBatchResult", breadcrumb: [{ name: "Text Sampler (TS)", path: "/samplerbatches" }, { name: "Result" }] },
    { path: "/exportResults", breadcrumb: [{ name: "Independent Validation (IV)", path: "/batchList" }, { name: "Export Results" }] },

    { path: "/openAIbatchList", breadcrumb: [{ name: "Icertis GPT (OpenAI)" }] },
    { path: "/openaicreatebatch", breadcrumb: [{ name: "Icertis GPT (OpenAI)", path: "/openAIbatchList" }, { name: "Create Batch" }] },
    { path: "/ai-result", breadcrumb: [{ name: "Icertis GPT (OpenAI)", path: "/openAIbatchList" }, { name: "Result" }] },

    { path: "/OpenAIGround", breadcrumb: [{ name: "Icertis GPT OpenAI Playground" }] },

    { path: "/OpenAIConversation", breadcrumb: [{ name: "Icertis GPT Conversation AI" }] },
    { path: "/OpenAIConversationDetails", breadcrumb: [{ name: "Icertis GPT Conversation AI", path: "/OpenAIConversation" }, { name: "Contract Details" }] },
];

export const RenderBreadcrumbs = () => {

    let history = useHistory();
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    let pubsub = CommonDataManager.getPubsub();

    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) {
        event.preventDefault();
        if (path && path.length > 0)
            history.push(path);
        else
            history.push("/home");
    }

    React.useEffect(() => {
        let fullWidth = pathnames?.includes("OpenAIConversation") || pathnames?.includes("OpenAIConversationDetails")
        pubsub.publish("SetFullWidth", fullWidth);
    }, [pathnames]);

    return (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {pathnames.length === 0 || pathnames.includes('default') ? <></> :
                    <Link underline="hover" key="1" color="primary" href="/" onClick={(event) => { handleClick(event, null) }}>
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> Home
                    </Link>
                }

                {pathnames.map((_value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const route: any = routes.filter(x => x.path === to);

                    return route[0]?.breadcrumb.map((breadcrumb, index2) => {
                        const lastBreadcrumb = index2 === route[0]?.breadcrumb?.length - 1;
                        return lastBreadcrumb
                            ?
                            (
                                <Typography color="text.primary" key={to}>
                                    {breadcrumb.name}
                                </Typography>

                            )
                            :
                            (
                                <Link underline="hover" key="1" color="primary" href="/" onClick={(event) => { handleClick(event, breadcrumb.path) }}>
                                    {breadcrumb.name}
                                </Link>
                            );

                    });
                })}
            </Breadcrumbs>
        </>
    )
}

