import { Button, Paper, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom"

export const SignedOut = () => {
    let history = useHistory();

    return (
        <>
            <Grid container spacing={3}>
                <Grid xs>
                </Grid>
                <Grid xs={5}>
                    <Paper sx={{ padding: 7 }} elevation={4}>
                        You have been signed out.
                        <br />
                        <br />
                        <Button size="small"
                            onClick={() => {
                                history.push("/");
                            }}
                            variant="outlined"
                        >
                            Click here
                        </Button> to sign in again.
                    </Paper>
                </Grid>
                <Grid xs>
                </Grid>
            </Grid>

        </>
    )
}