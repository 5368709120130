import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
//import { msalConfig } from "./authConfig";

import { createRoot } from 'react-dom/client';
// import { GlobalState } from './Context/GlobalState';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const rootElement = document.getElementById('root');

let idpProviders = (window as any).idpProviders;
let provider = idpProviders[0];

const msalConfig = {
    auth: {
        clientId: provider.clientId,
        authority: provider.authority,
        redirectUri: provider.redirectUri,
        navigateToLoginRequestUrl: provider.navigateToLoginRequestUrl,
        origin: provider.origin
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    }
};
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) 
  {
    const oldLocation = localStorage.getItem('location')
    const jwtToken = localStorage.getItem('jwtToken')
    if(oldLocation && jwtToken){
      localStorage.removeItem('location');
      //window.location.href = oldLocation;
    }
  } 
});
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter basename={baseUrl}>
    <MsalProvider instance={msalInstance}>
      {/* <GlobalState> */}
      <App />
      {/* </GlobalState> */}
    </MsalProvider>
  </BrowserRouter>);

registerServiceWorker();

