import * as React from 'react';
import { Alert, Button, Tab } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import { loginRequest } from '../authConfig';
import * as msal from "@azure/msal-browser";
import { useHistory } from "react-router-dom"
import BosonApiService from './services/BosonApi.Service';
import { Grid, Box, } from '@mui/material';

export const Default = () => {
    let history = useHistory();
    let msalInstance: msal.PublicClientApplication;
    let idpProviders = (window as any).idpProviders;
    let isP = false;

    /*React.useEffect(() => {
        if (localStorage.getItem('jwtToken')) {
            history.push('/home');
        }
    });*/

    const signIn = async (index) => {
        let provider = idpProviders[index];
        try {
            if (provider.isPartner) {
                isP = true;
            }
        }
        catch (e) {
            console.log(e);
        }
        const msalConfig = {
            auth: {
                clientId: provider.clientId,
                authority: provider.authority,
                redirectUri: provider.redirectUri,
                navigateToLoginRequestUrl: provider.navigateToLoginRequestUrl,
                origin: provider.origin
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: true,
            },
            system: {
                loadFrameTimeout: 6000, // Adjust timeout duration as needed
            }
        };

        msalInstance = new msal.PublicClientApplication(msalConfig);
        msalInstance.loginPopup(loginRequest)
            .then(handleResponse)
            .catch(error => {
                console.error(error);
            });
    };
    const getUserInformation = (username, email) => {
        BosonApiService.login(username, email, isP)
            .then((response) => {
                localStorage.setItem('jwtToken', response?.data?.token);
                window.location.href = '/home';
            })
            .catch((error) => {
                console.log(error);
            });
    };
    function handleResponse(response) {
        if (response !== null) {
            let username = response.account.username;
            localStorage.setItem('userName', username);
            localStorage.setItem('token', response.idToken);
            localStorage.setItem('jwtToken', response.accessToken);
            localStorage.setItem('accessToken', response.accessToken);
            getUserInformation(response.account.name, response.account.username);
        } else {
            console.log('token response is null');
            history.push("/default");
        }
    };
    return (  
        <>  
            <div className="col-md-6 offset-md-3 text-center" style={{ paddingTop: '18%',marginTop:"30px" }}>
                <div className="card">
                    <h4 className="card-header">AIML Portal Login Options</h4>
                    <div className="card-body">
                        {idpProviders.map((provider, index) => (
                            <div key={"provider_" + index} style={{marginTop:'10px',marginBottom:"20px"}}>
                                <Button  style={{ backgroundColor: '#6432A5', color: 'white', justifyContent: 'flex-start', width:'100%' }} title={provider.help} onClick={() => { signIn(index) }} >
                                    <img src={provider.iconURL} style={{ height: "50px" }}></img>
                                    <span style={{ paddingLeft: '10px' }}>{provider.displayName}</span>
                                </Button>
                                <div style={{marginTop:"10px",color:"black", fontSize:"x-small"}}>{provider.message}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
        </>   
    );
}