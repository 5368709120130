import { createTheme } from '@mui/material/styles';


export const DefaultTheme = createTheme({
    typography: {
        fontFamily: [

            'work sans',

        ].join(','),
    },
    palette: {
        primary: {
            main: '#6432A5'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                        backgroundColor: '#6432A5'
                    }),
                }),
            },
        },
    },
});