import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";


/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    
    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
           instance.logoutRedirect({
                postLogoutRedirectUri: "/signedout",
            });
        }
    }

    return (
        <Button onClick={() => handleLogout("redirect")}>Sign out</Button>
    );
}