import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';

import './custom.css'
import { Login } from './components/Account/Login';
import { Progress } from './components/Progress';

import { ThemeProvider } from '@mui/material/styles';
import { DefaultTheme } from './themes/DefaultTheme';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import { SignedOut } from './components/Account/SignedOut';

import { IVServices } from './components/IVServices';
import { Result } from './components/Pages/Result';
import BosonApiService from './components/services/BosonApi.Service';
import { useLocation, useHistory } from 'react-router-dom';
import { Default } from './components/Default';
import { Alert } from '@mui/material';


export const App = () => {

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = React.useState(null);
  const location = useLocation();
  const history = useHistory();
  const pathnames = location.pathname.split('/').filter((x) => x);
  //const name = accounts[0] && accounts[0].name;

  const requestToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response: any) => {
      console.log(response);
      setAccessToken(response.idToken);
      localStorage.setItem('token', response.idToken);
      localStorage.setItem('accessToken', response.accessToken);
      getUserInformation(response.account.name, response.account.username);
      console.debug(accessToken);
    }).catch((e) => {
      console.log("Acquiring token silent failed: " + e);
      console.log("Acquiring token using popup");
      instance.acquireTokenPopup(request).then((response: any) => {
        console.log(response);
        setAccessToken(response.accessToken);
        localStorage.setItem('token', response.idToken);
        localStorage.setItem('accessToken', response.accessToken);
        getUserInformation(response.account.name, response.account.username);
      });
    });
  }

  const getUserInformation = (username, email) => {
    BosonApiService.login(username, email, '')
      .then((response) => {
        localStorage.setItem('jwtToken', response?.data?.token);
        const oldLocation = localStorage.getItem('location')
        if (oldLocation) {
          localStorage.removeItem('location');
          //window.location.href = oldLocation;
        }
        else {
          window.location.reload();//refresh the page after recieving jwt token
        }
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem('location');
      });
  }
  const releaseToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('location');
  }

  React.useEffect(() => {
      if (pathnames.length === 0 || pathnames.includes('default')) {//
          releaseToken();
      }
      else if (localStorage.getItem('jwtToken') == null || localStorage.getItem('jwtToken') == undefined) {
          window.location.href = '/';
      }
      else if (isAuthenticated && !localStorage.getItem('jwtToken') && location.pathname.indexOf('signedout') == -1) {
          requestToken();
      }
      else if (location.pathname.indexOf('signedout') > -1) {
          releaseToken();
      }
  });

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Layout>
        {(window as any).EnvironmentConfiguration.Notice.show 
         && <Alert sx={{mb:2,borderRadius:"10px"}} severity="warning">    
          <div dangerouslySetInnerHTML={{ __html: (window as any).EnvironmentConfiguration.Notice.text}}/>
        </Alert>}
        
        <Route exact path='/' component={Default} />
        <Route exact path='/default' component={Default} />
        <Route path='/home' component={IVServices} />
        <Route path='/progress' component={Progress} />
        <Route path='/result' component={Result} />
        <Route path='/signedout' component={SignedOut} />
      </Layout>
    </ThemeProvider>
  )

}
