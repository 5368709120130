import axios, { AxiosRequestConfig } from "axios";
import HttpService from "./HttpService";

const BosonApiService = {
    login: function (userName: any, password: any, isPartnerUser: any) {
    const LOGIN_URL = "users/authenticate";
        return HttpService.login(LOGIN_URL, userName, password, isPartnerUser);
    },

  userInfo: function (email: any) {
    const USER_URL = `users/account?email=${email}`;
    return HttpService.get(USER_URL);
  },

  getUserClaims:function(){
    const USER_URL=`users/getuserclaims`;
    return HttpService.get(USER_URL);
  },

  userEnvironments: function (identifier: any) {
    const USER_URL = `users/userenvironments?identifier=${identifier}`;
    return HttpService.get(USER_URL);
  },

    getActivities: function () {
        const ACTIONS_URL = `batches/activities`;
        return HttpService.get(ACTIONS_URL);
    },

    getIciVersions: function () {
    const ACTIONS_URL = `iciversions`;
    return HttpService.get(ACTIONS_URL);
  },

  donloadManual: function() {
    const USER_URL=`users/downloadmanual`;
    return HttpService.downloadResultsFiles(USER_URL,'UserManual.pdf');
  }
 
  
};

export default BosonApiService;
