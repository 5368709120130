import Pubsub from "./Pubsub/Pubsub";


export default class CommonDataManager {


    static pubsub: Pubsub = null;

    /**
     * @returns {Pubsub}
     */
    static getPubsub() {
        if (CommonDataManager.pubsub == null) {
            CommonDataManager.pubsub = new Pubsub();
        }

        return this.pubsub;
    }

}