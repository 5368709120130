import { Box, Container } from '@mui/material';
import React from 'react';
import CommonDataManager from '../util/CommonDataManager';
import { RenderBreadcrumbs } from './Breadcrumbs';
import Footer from './Footer';
import PrimarySearchAppBar from './NavMenu';

export default function Layout(props) {
  let pubsub = CommonDataManager.getPubsub();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [fullWidth, setFullWIdth] = React.useState(false);

  const fullWidthContainer = (fullWidthParam) => {
    setFullWIdth(fullWidthParam);
  }

  React.useEffect(() => {
    console.log("fullWidth");
    console.log(fullWidth);
  }, [fullWidth]);

  pubsub.subscription("SetFullWidth", fullWidthContainer).subscribe();

  return (
    <>

      {pathnames.length === 0 || pathnames.includes('default') ? <></> :
        <Box mb={2}>
          <PrimarySearchAppBar fullWidth={fullWidth} />
        </Box>
      }


      <Box mb={4}>

        <Container maxWidth={fullWidth ? false : "lg"}>
          <RenderBreadcrumbs />
        </Container>

      </Box>

      <Container maxWidth={fullWidth ? false : "lg"}>
        {props.children}
      </Container>

      <Footer />
    </>
  );

}
