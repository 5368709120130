import React from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { SignOutButton } from './Account/SignOut';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Avatar, Container, FormControlLabel, FormGroup, Stack, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { callMsGraphPhoto } from './Account/Graph';

export const NavMenu = () => {
  const isAuthenticated = useIsAuthenticated();

  const { accounts } = useMsal();


  const name = accounts[0] && accounts[0].name;

  // constructor (props) {
  //   super(props);

  //   this.toggleNavbar = this.toggleNavbar.bind(this);
  //   this.state = {
  //     collapsed: true
  //   };
  // }

  // toggleNavbar () {
  //   this.setState({
  //     collapsed: !this.state.collapsed
  //   });
  // }


  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <Container>
          <NavbarBrand tag={Link} to="/">Icertis AI/ML</NavbarBrand>
          <NavbarToggler onClick={() => { }} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse"
            // isOpen={!this.state.collapsed} 
            navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>
            </ul>
          </Collapse>
          {isAuthenticated &&
            <>
              {name} -
              <SignOutButton />
            </>}
        </Container>
      </Navbar>
    </header>
  );

}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface IPrimarySearchAppBar {
  fullWidth: boolean
}

export default function PrimarySearchAppBar(props: IPrimarySearchAppBar) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isAuthenticated = useIsAuthenticated();

  const { accounts } = useMsal();

  const [profileImage, setProfileImage] = React.useState(null);

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;
  // const [globalState, updateGlobalState] = useGlobalState();

  const validateResponse = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

 React.useEffect(() => {
    if (isAuthenticated) {
      callMsGraphPhoto(localStorage.getItem('accessToken'))
        .then(validateResponse)
        .then(response => response.blob())
        .then(blob => {
          try{
          var reg = /^(ftp|http|https):\/\/[^ "]+$/; //URL validation for Veracode scan
          if(reg.test(URL.createObjectURL(blob).split('blob:')[1])){
          setProfileImage(URL.createObjectURL(blob));}
          }
          catch(e){
            setProfileImage(null);
          }
        })
        .catch((_error) => {
          setProfileImage(null);
        });
    }


    // callMsGraphPhoto(localStorage.getItem("token")).then((response) => {
    //   debugger;
    //   setProfileImage(response.body)
    // }).catch((_error) => {
    //   debugger;
    //   setProfileImage(null)
    // });
  }, [isAuthenticated]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuthenticated &&
        <>
          <MenuItem>{name}</MenuItem>
          <MenuItem> <SignOutButton /></MenuItem>
        </>
      }
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isAuthenticated &&
        <>
          <MenuItem>{name}</MenuItem>
          <MenuItem> <SignOutButton /></MenuItem>
        </>
      }
    </Menu>
  );



  const advanceModeChange = (event) => {
    console.log(event.target.checked);
    // updateGlobalState("AdvanceMode", event.target.checked);
    // console.log("globalState");
    // console.log(globalState);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container maxWidth={props.fullWidth ? false : "lg"}>
          <Toolbar>
            <NavbarBrand tag={Link} to="/home" style={{ color: "#fff" }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                Icertis AI/ML
              </Typography>
            </NavbarBrand>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex', backgroundColor: '#f2f2f2', borderRadius: '10px', color: '#404041', paddingLeft: '7px', paddingRight: '7px', margin: '3px' } }}>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <Stack direction={"column"} sx={{ alignItems: "flex-end" }}>
                  <Typography variant='caption'>
                    <strong>{name}</strong>
                  </Typography>
                  <Typography variant='caption'>
                    {email}
                  </Typography>
                  {/* <Typography variant='caption' fontSize={"10px"}>
                    <Switch size='small' value={globalState.AdvanceMode} onChange={(event) => { advanceModeChange(event) }} />
                    Advance Mode
                  </Typography>                   */}
                </Stack>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {
                    profileImage
                      ?
                      <Avatar
                        alt={name}
                        src={profileImage}
                      />

                      :
                      <AccountCircle />
                  }
                </IconButton>
              </Stack>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
